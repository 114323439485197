import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet-async';
import { useTheme } from 'styled-components';
import FsLightbox from "fslightbox-react";

import { SIZES } from '../../../utils/constants.js';
import { Img } from '../../../utils/styles/images.js';
import { BgColor, BgMedia, BgMediaBody, BgMediaContainer, BgMediaHeading, BgMediaModal, Centered, FullWidthLine } from '../../../utils/styles/misc';
import { Body, H1, H3, LLink } from '../../../utils/styles/text';
import { Column, Row, Grid } from '../../../utils/styles/misc';
import { Button } from '../../../utils/styles/forms';
import { CgChevronDown } from 'react-icons/cg';
import { RiPlayCircleFill } from 'react-icons/ri';

function Home(props){
    const theme = useTheme();

    const [width, setWidth] = useState(window.innerWidth);	

    const [lightboxController, setLightboxController] = useState({
		toggler: false,
		slide: 1,
        sources: [
            "https://i.imgur.com/fsyrScY.jpg",
            "https://www.youtube.com/watch?v=3nQNiWdeH2Q",
            "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
        ]
	});
    
	function openLightboxOnSlide(sources, number) {
		setLightboxController({
			toggler: !lightboxController.toggler,
			slide: number,
            sources: sources,
		});
	}

    useEffect(() => {
        const handleResizeWindow = () => setWidth(window.innerWidth);
        // subscribe to window resize event "onComponentDidMount"
        window.addEventListener("resize", handleResizeWindow);
        return () => {
            // unsubscribe "onComponentDestroy"
            window.removeEventListener("resize", handleResizeWindow);
        };
    }, [])

    const persons = [
        {
            name: "Phil Shafer",
            portrait: require("../../../assets/images/persons/artist/ArtistPortrait.jpg"),
            description: "Phil Shafer is an artist and mural painter whose business, Sike Style Industries, has created colorful murals, indoors and outdoors, all over Kansas City.",
            backgrounds: [
                require("../../../assets/images/persons/artist/ArtistBackground.png"),
                require("../../../assets/images/persons/artist/ArtistMobileBG.png")
            ],
            vidUrls: [
                "https://www.youtube.com/watch?v=_M6rQ2G9ZtE",
                "https://www.youtube.com/watch?v=pqA6hwsHBqQ",
                "https://www.youtube.com/watch?v=C6Rc7FOnGa0",
                "https://www.youtube.com/watch?v=S8p7AjCUpvw",
            ],
            questions: [
                "What Was Your Favorite Book As A Kid?",
                "What Do You Need To Learn To Be An Artist?",
                "Who Helped You Get To Where You Are Now?",
            ],
        },
        {
            name: "AD Franch",
            portrait: require("../../../assets/images/persons/athlete/AthletePortrait.jpg"),
            description: "AD Franch plays goalkeeper for the Kansas City Current in the National Women's Soccer League.",
            backgrounds: [
                require("../../../assets/images/persons/athlete/AthleteBackground.png"),
                require("../../../assets/images/persons/athlete/AthleteMobileBG.png")
            ],
            vidUrls: [
                "https://www.youtube.com/watch?v=qLEj4BmWbHE",
                "https://www.youtube.com/watch?v=xwmJ6IogO6E",
                "https://www.youtube.com/watch?v=-wDhNGE4A7I",
                "https://www.youtube.com/watch?v=-DvN9r5jVaM"
            ],
            questions: [
                "What Do You Like Most About Playing Soccer?",
                "What Do You Like To Do In Your Free Time?",
                "What Did You Need To Learn To Be A Goalkeeper?",
            ],
        },
        {
            name: "Mark Launiu",
            portrait: require("../../../assets/images/persons/clothier/portrait_clothing.jpg"),
            description: "Mark Launiu is the co-founder of MADE MOBB Urban Apparel, a store selling t-shirts, sweatshirts, and baseball hats inspired by Midwest values: hard work, craftsmanship, and community.",
            backgrounds: [
                require("../../../assets/images/persons/clothier/ClothierBackground.png"),
                require("../../../assets/images/persons/clothier/ClothierMobileBG.png")
            ],
            vidUrls: [
                "https://www.youtube.com/watch?v=E9sHW7S5QgI",
                "https://www.youtube.com/watch?v=vRwG8MM7QgQ",
                "https://www.youtube.com/watch?v=_P0aDN7XP_o",
                "https://www.youtube.com/watch?v=sswij574NtQ",
            ],
            questions: [
                "What Do You Like To Read As An Adult?",
                "Who Helped You Get Where You Are Now?",
                "Who Taught You How To Read?",
            ],
        },
        {
            name: "Helen Jo Leach & Johnny Leach",
            portrait: require("../../../assets/images/persons/chef/portrait_chef.jpg"),
            description: "Helen Jo Leach is a master baker and chocolatier, and her husband Johnny is a chef. Together, they create the meals and desserts at The Town Company restaurant in the Hotel Kansas City.",
            backgrounds: [
                require("../../../assets/images/persons/chef/ChefBackground.png"),
                require("../../../assets/images/persons/chef/ChefMobileBG.png")
            ],
            vidUrls: [
                "https://www.youtube.com/watch?v=fCvEbcQTeN8",
                "https://www.youtube.com/watch?v=hg9zsN_-VNk",
                "https://www.youtube.com/watch?v=6SUX-WEWrS0",
                "https://www.youtube.com/watch?v=lmCO8KR1B1o",
            ],
            questions: [
                "How Did You Learn To Be A Chef?",
                "When Did You Decide To Be A Chef?",
                "What Do You Like To Do Outside Of Work?",
            ],
        },
        {
            name: "Rupal Gupta",
            portrait: require("../../../assets/images/persons/doctor/portrait_doctor.jpg"),
            description: "Dr. Rupal Gupta is a pediatrician, a doctor that cares for children. She takes care of children in a Children's Mercy Hospital clinic at a large childcare program in Kansas City.",
            backgrounds: [
                require("../../../assets/images/persons/doctor/DoctorBackground.png"),
                require("../../../assets/images/persons/doctor/DoctorMobileBG.png")
            ],
            vidUrls: [
                "https://www.youtube.com/watch?v=hcdr-KyCPek",
                "https://www.youtube.com/watch?v=3ofnU69azrE",
                "https://www.youtube.com/watch?v=95U0PEtz21I",
                "https://www.youtube.com/watch?v=c2vZC6LUYYs",
            ],
            questions: [
                "What Did You Learn To Become A Doctor?",
                "What Do You Like To Read As An Adult?",
                "Do You Remember Learning To Read?",
            ],
        },
        {
            name: "Brooke Salvaggio & Dan Heryer",
            portrait: require("../../../assets/images/persons/farmer/portrait_farmer.jpg"),
            description: "Urbavore Urban Farm is both home and work for Brooke Salvaggio and Dan Heryer. On about 13 acres of land in Kansas City, they grow crops of fruits and vegetables.",
            backgrounds: [
                require("../../../assets/images/persons/farmer/FarmerBackground.png"),
                require("../../../assets/images/persons/farmer/FarmerMobileBG.png")
            ],
            vidUrls: [
                "https://www.youtube.com/watch?v=KArqCI1WGHY",
                "https://www.youtube.com/watch?v=-eHG9Lo-ss8",
                "https://www.youtube.com/watch?v=JDjR5C1aRao",
                "https://www.youtube.com/watch?v=39FztvrpaOk",
            ],
            questions: [
                "What Do You Like Most About Farming?",
                "When Did You Know You Wanted To Own A Farm?",
                "How Do You Apply Your Reading Skills?",
            ],
        },
        {
            name: "Gia Trapani & Jen Orton",
            portrait: require("../../../assets/images/persons/groomer/portrait_dog_groomer.jpg"),
            description: "Gia Trapani went to college to study journalism and Jen Orton studied Spanish and French, but they both turned their love of dogs into a business.",
            backgrounds: [
                require("../../../assets/images/persons/groomer/GroomerBackground.png"),
                require("../../../assets/images/persons/groomer/GroomerMobileBG.png")
            ],
            vidUrls: [
                "https://www.youtube.com/watch?v=wvgZ0lO-czc",
                "https://www.youtube.com/watch?v=nzswl1YkbPs",
                "https://www.youtube.com/watch?v=EvEwk2Yk9vU",
                "https://www.youtube.com/watch?v=GjD-m_DBz7M"
            ],
            questions: [
                "What Was Your Favorite Book As A Kid?",
                "What Do You Like To Read As An Adult?",
                "How Often Do You Read As A Groomer?",
            ],
        },
        {
            name: "Shomari Benton",
            portrait: require("../../../assets/images/persons/lawyer/portrait_law.jpg"),
            description: "Shomari Benton went to law school and decided to use his knowledge of the law to make sure that the city of Kansas City has buildings and spaces that serve the needs of all people.",
            backgrounds: [
                require("../../../assets/images/persons/lawyer/LawyerBackground.png"),
                require("../../../assets/images/persons/lawyer/LawyerMobileBG.png")
            ],
            vidUrls: [
                "https://www.youtube.com/watch?v=a0bsAxgj4Bw",
                "https://www.youtube.com/watch?v=AEX7Jk2f64w",
                "https://www.youtube.com/watch?v=LsnSpycOMek",
                "https://www.youtube.com/watch?v=fFAN9ePpbW4",
            ],
            questions: [
                "What Do You Like To Do Outside Of Work?",
                "Who Taught You To Read?",
                "How Can I Become A Lawyer?",
            ],
        },
        {
            name: "Pablo Sanhueza",
            portrait: require("../../../assets/images/persons/musician/portrait_musician.jpg"),
            description: "Pablo Sanhueza moved to Kansas City from Santiago, Chile in the 1990s. He brought his love of music from Latin America and his skills as a percussionist and bandleader.",
            backgrounds: [
                require("../../../assets/images/persons/musician/MusicianBackground.png"),
                require("../../../assets/images/persons/musician/MusicianMobileBG.png")
            ],
            vidUrls: [
                "https://www.youtube.com/watch?v=zKz7yg0jIPk",
                "https://www.youtube.com/watch?v=H6x658aAAOE",
                "https://www.youtube.com/watch?v=5giQGqlScPI",
                "https://www.youtube.com/watch?v=Lq6Xn2mBfGQ",
            ],
            questions: [
                "What Was Your Favorite Book As A Kid?",
                "What Are Your Interests Outside Of Music?",
                "Who Helped You Get To Where You Are?",
            ],
        },
        {
            name: "Luis Virgil",
            portrait: require("../../../assets/images/persons/police/portrait_police.jpg"),
            description: "Luis Virgil wanted to be a police officer ever since he was a child. He became an officer with the Independence Police Department.",
            backgrounds: [
                require("../../../assets/images/persons/police/PoliceBackground.png"),
                require("../../../assets/images/persons/police/PoliceMobileBG.png")
            ],
            vidUrls: [
                "https://www.youtube.com/watch?v=PubTpYGLnhg",
                "https://www.youtube.com/watch?v=qoFRvrORQTk",
                "https://www.youtube.com/watch?v=6I2yb1nzg08",
                "https://www.youtube.com/watch?v=SJimQzoiEno",
            ],
            questions: [
                "Who Taught You How To Read?",
                "What Do You Like To Do Outside Of Work?",
                "When Did You First Want To Be A Police Officer?",
            ],
        },
        {
            name: "Laura Norris",
            portrait: require("../../../assets/images/persons/restaurateur/portraits_rest_owner.jpg"),
            description: "Laura Norris created Ragazza to be a cozy restaurant that serves guests like they are family.",
            backgrounds: [
                require("../../../assets/images/persons/restaurateur/RestaurantBackground.png"),
                require("../../../assets/images/persons/restaurateur/RestauranteurMobileBG.png")
            ],
            vidUrls: [
                "https://www.youtube.com/watch?v=lwwvxdUZk8Q",
                "https://www.youtube.com/watch?v=aDagPvqpToM",
                "https://www.youtube.com/watch?v=O_b2EBQ1TtM",
                "https://www.youtube.com/watch?v=AECqZIPzf48",
            ],
            questions: [
                "What Did You Like To Read As A Kid?",
                "What Do You Like To Read As An Adult?",
                "What's The Best Part Of Owning A Restaurant?",
            ],
        },
        {
            name: "Alejandro Sanchez Alvarado",
            portrait: require("../../../assets/images/persons/scientist/portrait_scientist.jpg"),
            description: "Dr. Alejandro Sánchez Alvarado grew up in Venezuela and moved to the United States to go to college and graduate school.",
            backgrounds: [
                require("../../../assets/images/persons/scientist/ScientistBackground.png"),
                require("../../../assets/images/persons/scientist/ScientistMobileBG.png")
            ],
            vidUrls: [
                "https://www.youtube.com/watch?v=t9fXQlcvJDg",
                "https://www.youtube.com/watch?v=7ny2AcC14g0",
                "https://www.youtube.com/watch?v=G-12oBRXTxI",
                "https://www.youtube.com/watch?v=hctqlZJp-04",
            ],
            questions: [
                "What Did You Learn To Become A Scientist?",
                "What Do You Like To Do Outside Of Your Work?",
                "What Do You LIke To Read As An Adult?",
            ],
        },
    ];

    const PersonSectionDesktop = (props) => (
        <>
        <div className="d-container">
            <div className="vignette"></div>
            <Img className="bg-img" src={props.backgrounds[0]} alt="background" width="100vw" />
            <div className="d-top-left">
                <Grid fluid style={{ padding: "10px 20px", backgroundColor: theme.colors.red, borderRadius: "5px", maxWidth: "30vw"}}>
                    <Row justify="center" align="center">
                        <Column sm={12} md={4} style={{ borderRight: "1px solid white" }} >
                            <Img
                                width="600px"
                                style={{cursor: "pointer"}}
                                alt={`portrait ${props.name}`}
                                src={props.portrait}
                            />
                        </Column>
                        <Column sm={12} md={8}>
                            <H3 margin="0 0 0 10px" color="white">{props.name}</H3>
                            <Body margin="0 0 0 10px" color="white">{props.description}</Body>
                            <Button 
                                size={SIZES.SM}
                                onClick={() => openLightboxOnSlide(props.vidUrls, 1)}
                            >
                                About {props.name.substring(0, props.name.indexOf(" "))} <RiPlayCircleFill size={18} />
                            </Button>
                        </Column>
                    </Row>
                </Grid>
            </div>
            <div className="d-top-right">
                <Button
                    style={{padding: "30px 30px"}}
                    onClick={() => openLightboxOnSlide(props.vidUrls, 2)} 
                    size={SIZES.LG}
                    type="button"
                >
                    {props.questions[0]} <RiPlayCircleFill size={22} />
                </Button>
            </div>
            <div className="d-bottom-left">
                <Button 
                    style={{padding: "30px 30px"}}
                    onClick={() => openLightboxOnSlide(props.vidUrls, 3)} 
                    size={SIZES.LG}
                    type="button"
                >
                    {props.questions[1]} <RiPlayCircleFill size={22} />
                </Button>
            </div>
            <div className="d-bottom-right">
                <Button 
                    style={{padding: "30px 30px"}}
                    onClick={() => openLightboxOnSlide(props.vidUrls, 4)} 
                    size={SIZES.LG}
                    type="button"
                >
                    {props.questions[2]} <RiPlayCircleFill size={22} />
                </Button>
            </div>
        </div>
          
        </>
    )

    const PersonSectionMobile = (props) => (
        <>
        
            <Img 
                alt={`background mobile`}
                src={props.backgrounds[1]}
                height="auto"
                style={{minWidth:"100%"}}
            />
            <Grid fluid>
                <Row justify="center" align="center">
                    <Column sm={12} style={{ padding: "10px 20px", backgroundColor: theme.colors.red, borderRadius: "5px", maxWidth: "600px"}}>
                        <Row align="center">
                            <Column sm={12} md={4} style={{ borderRight: "1px solid white" }} >
                                <Img
                                    width="200px"
                                    style={{cursor: "pointer"}}
                                    alt={`portrait ${props.name}`}
                                    src={props.portrait}
                                    onClick={() => openLightboxOnSlide(props.vidUrls, 1)}
                                />
                            </Column>
                            <Column sm={12} md={8}>
                                <H3 margin="0 0 0 10px" color="white">{props.name}</H3>
                                <Body margin="0 0 0 10px" color="white">{props.description}</Body>
                            </Column>
                        </Row>
                    </Column>
                    <Column sm={12} textalign="center">
                        <Button
                            onClick={() => openLightboxOnSlide(props.vidUrls, 2)} 
                            size={SIZES.LG}
                            type="button"
                        >
                            {props.questions[0]}
                        </Button>
                    </Column>
                </Row>
                <Row justify="center" align="center">
                    <Column sm={12} textalign="center">
                        <Button 
                            onClick={() => openLightboxOnSlide(props.vidUrls, 3)} 
                            size={SIZES.LG}
                            type="button"
                        >
                            {props.questions[1]}
                        </Button>
                    </Column>
                    <Column sm={12} textalign="center">
                        <Button 
                            onClick={() => openLightboxOnSlide(props.vidUrls, 4)} 
                            size={SIZES.LG}
                            type="button"
                        >
                            {props.questions[2]}
                        </Button>
                    </Column>
                </Row>
            </Grid>
        </>
    )

    return (
        <>
            <Helmet>
                <title>Home {props.site.name ? `| ${props.site.name}` : ""}</title>
            </Helmet>
            <BgMediaContainer>
                <BgColor
                    bgColor={theme.colors.primary}
                >
                    <BgMedia
                        alt="hero background" 
                        // Honestly the below snippet somehow works so the little "image not found" icon with the alt tag doesn't pop up in the upper left of the banner bgColor, so don't remove the below til we find a better solution lol
                        src={
                            "https://firebasestorage.googleapis.com/v0/b/beingmekc-org.appspot.com/o/public%2Fbanners%2FFinalPage(2)-og-seo.png?alt=media&token=d3e3e65b-760e-469b-b421-96b3464dd622"
                            ?? 
                            require("../../../assets/images/misc/blank-bg.png")
                        }
                    />
                </BgColor>
                <BgMediaModal>
                    <BgMediaHeading color={"white"}>Thank you for supporting <br/> Turn the Page KC!</BgMediaHeading>
                    <BgMediaBody textAlign="center">
                        <Body color={"white"}>
                            Come along as we explore Kansas City and meet the diverse, creative people that make our city the place where everyone can say, I Can Be ME in KC!
                            You'll learn about following your interests and developing your skills as we meet the fascinating people who have succeeded in making their professional dreams a reality.
                        </Body>
                        <Body color={"white"}>
                            Share this book with children, support their love of reading, and help make their dreams a reality too!
                        </Body>
                        <LLink to="/#anchored">
                            <Button size={SIZES.LG} color={theme.colors.red}>
                                Explore below!<CgChevronDown size={24} />
                            </Button>
                        </LLink>
                    </BgMediaBody>
                </BgMediaModal>
            </BgMediaContainer>
            <FullWidthLine color={theme.colors.primary} height="auto" padding="50px 0">
                <Centered>
                    <H1 id="anchored" margin="0" color="white" tex>Meet the Professionals</H1>
                </Centered>
            </FullWidthLine>

            {/* Desktop */}
            {width > 1200 && (
                <>
                    {persons.map((person, p) => {
                        return (
                            <PersonSectionDesktop
                                key={p}
                                offset={p+1}
                                name={person.name}
                                description={person.description}
                                backgrounds={person.backgrounds}
                                portrait={person.portrait}
                                vidUrls={person.vidUrls}
                                questions={person.questions}
                            />
                        )
                    })}
                    <Img
                        width="100%"
                        src={require("../../../assets/images/misc/FinalPage.jpg")}
                    />
                </>
            )}

            {/* Mobile */}
            {width <= 1200 && (
                <>
                    {
                        persons.map((person, p) => {
                            return (
                                <PersonSectionMobile
                                    key={p}
                                    offset={p+1}
                                    name={person.name}
                                    description={person.description}
                                    backgrounds={person.backgrounds}
                                    portrait={person.portrait}
                                    vidUrls={person.vidUrls}
                                    questions={person.questions}
                                />
                            )
                        })
                    }
                    <Img
                        width="100%"
                        src={require("../../../assets/images/misc/EndMobileBG.png")}
                    />
                </>
            )}

			<FsLightbox
                type="youtube"
				toggler={lightboxController.toggler}
				sources={lightboxController.sources}
				slide={lightboxController.slide}
			/>
        </>
    );
}

export default Home;