import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Img } from '../../../utils/styles/images';
import { Column, Grid, Wrapper, Row, Centered, FullWidthLine, Div } from '../../../utils/styles/misc';
import { ALink, Body, H1, H2, H3, LLink } from '../../../utils/styles/text';
import ContactForm from '../../misc/ContactForm';
import { Button } from '../../../utils/styles/forms';
import { SIZES } from '../../../utils/constants.js';
import { useTheme } from 'styled-components';


function Curriculum(props){

    const theme = useTheme();


    return (
        <>
            <Helmet>
                <title>Curriculum {props.site.name ? `| ${props.site.name}` : ""}</title>
            </Helmet>
            
            <Wrapper>
                {/* <Button><FaChevronLeft /> Back to main page</Button> */}
                <H1>Curriculum</H1>
                
                
                

                <Grid>
                    <Row>
                        <Column>
                            <Centered>
                                <ALink href="https://drive.google.com/uc?export=download&id=1d54qHXnKHm3-rot-oa59WqmZDnZn83AX">
                                    <Button size={SIZES.LG} color={theme.colors.red}>Download K-1st Curriculum</Button>
                                </ALink>
                            </Centered>
                        </Column>
                        <Column>
                            <Centered>
                                <ALink href="https://drive.google.com/uc?export=download&id=1PUdGws2GJAEG3SnIhCRFlvzsIBHW1gYm">
                                    <Button size={SIZES.LG} color={theme.colors.red}>Download 2nd-3rd Curriculum</Button>
                                </ALink>
                            </Centered>
                        </Column>
                        <Column>
                            <Centered>
                                <ALink href="https://www.youtube.com/@icanbemeinkc/playlists">
                                    <Button size={SIZES.LG} color={theme.colors.red}>YouTube Playlists</Button>
                                </ALink>
                            </Centered>
                        </Column>
                    </Row>
                </Grid>

                {/* <Grid fluid>
                    <Row> 
                        <ALink href="https://www.youtube.com/watch?v=vRwG8MM7QgQ&list=PLAVx33CtAd0c0pkji_sLUHhurmNa2l9i9" target="_blank">
                            <H2><li>What Do You Like To Read As An Adult?</li></H2>
                        </ALink>
                        <ALink href="https://www.youtube.com/watch?v=-wDhNGE4A7I&list=PLAVx33CtAd0crzJlDjCcTRIQqT-Nm4OI5" target="_blank">
                            <H2><li>What Do You Like To Do In Your Free Time?</li></H2>
                        </ALink>
                        <ALink href="https://www.youtube.com/watch?v=C6Rc7FOnGa0&list=PLAVx33CtAd0d80Zio4ef1C9hjJupSLYFg" target="_blank">
                            <H2><li>What Did You Need To Learn For Your Job?</li></H2>
                        </ALink>
                        <ALink href="https://www.youtube.com/watch?v=pqA6hwsHBqQ&list=PLAVx33CtAd0eHKWqFGv_4wS9nXv9h1Fmd" target="_blank">
                            <H2><li>What Was Your Favorite Book As A Kid?</li></H2>
                        </ALink>
                        <ALink href="https://www.youtube.com/watch?v=xwmJ6IogO6E&list=PLAVx33CtAd0eLgA1GMo8Z1oYTVaAQCjMs" target="_blank">
                            <H2><li>What Do You Like Most About Your Job?</li></H2>
                        </ALink>
                        <ALink href="https://www.youtube.com/watch?v=sswij574NtQ&list=PLAVx33CtAd0erO7sm16iwPO62u2TL1k4j" target="_blank">
                            <H2><li>Who Taught You To Read?</li></H2>
                        </ALink>
                        <ALink href="https://www.youtube.com/watch?v=6SUX-WEWrS0&list=PLAVx33CtAd0f18jp1UgQaXsMdaNNy0_OY" target="_blank">
                            <H2><li>When/How Did You Decide To Do Your Job?</li></H2>
                        </ALink>
                        <ALink href="https://www.youtube.com/watch?v=S8p7AjCUpvw&list=PLAVx33CtAd0cv3CutCi7Nr9v_xT91Bjgj" target="_blank">
                            <H2><li>Who Helped You Get Where You Are Now?</li></H2>
                        </ALink>
                        <ALink href="https://www.youtube.com/watch?v=GjD-m_DBz7M&list=PLAVx33CtAd0eeTqmjOWKCrzhBAvmhZcD3" target="_blank">
                            <H2><li>How Often Do You Read?</li></H2>
                        </ALink>
                    </Row>
                </Grid> */}
            </Wrapper>
                
            <FullWidthLine height="auto">
                <Centered style={{padding: "25px 0"}}>
                    <H2 color={"white"} margin="0">
                        Brought to you by:
                    </H2>
                    <ALink href="https://turnthepagekc.org/" target="_blank">
                        <Img 
                            width={"500px"}
                            src={require("../../../assets/images/logos/turn_the_page_logo.png")}
                        />
                    </ALink>
                </Centered>
            </FullWidthLine>
            <Wrapper>
                <ContactForm />
            </Wrapper>
        </>
    );
}

export default Curriculum;