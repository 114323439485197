import React from 'react';
import { NavLLink, NavLogo, NavTitle, HeaderContainer, NavLinks, NavMenuContainer, BrandContainer, BrandLink, NavALink } from '../../utils/styles/header';
import { FullWidthLine } from '../../utils/styles/misc';

function Header(props) {
    const navLinks = [
        {
            label: "Home",
            path: "/",
            type: ""
        },
        {
            label: "About",
            path: "/about",
            type: ""
        },
        {
            label: "Turn the Page KC",
            path: "https://turnthepagekc.org/",
            type: "external"
        },
        {
            label: "Curriculum",
            path: "/curriculum",
            type: ""
        }
    ];
    
    return (
        <>
        <HeaderContainer>
            <BrandContainer>
                <BrandLink to="/" height={props.site.logo.height} end>
                    <NavLogo 
                        width={props.site.logo.width}
                        height={props.site.logo.height}
                        margin="0" 
                        src={require("../../assets/images/logos/logo.png")}
                    />
                    {props.site.logo.showTitle && (<NavTitle removeActiveStyle>{props?.site?.name ?? ""}</NavTitle>)}
                </BrandLink>
            </BrandContainer>

            {/* Desktop menu */}
            <NavMenuContainer>
                <NavLinks>
                    {navLinks.map((link, l) => {
                        if (link.type === "external") {
                            return (
                                <NavALink key={l} href={link.path} target={"_blank"}>{link.label}</NavALink>
                            )
                        } else {
                            return (
                                <NavLLink key={l} to={link.path}>{link.label}</NavLLink>
                            )
                        }
                    })}
                </NavLinks>
            </NavMenuContainer>
            

        </HeaderContainer>
        <FullWidthLine height={"5px"}/>
        </>
    )
}

export default Header;