import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Img } from '../../../utils/styles/images';
import { Column, Grid, Wrapper, Row, Centered, FullWidthLine } from '../../../utils/styles/misc';
import { ALink, Body, H1, H2, H3 } from '../../../utils/styles/text';
import ContactForm from '../../misc/ContactForm';

function About(props){
    return (
        <>
            <Helmet>
                <title>About {props.site.name ? `| ${props.site.name}` : ""}</title>
            </Helmet>
            
            <Wrapper>
                {/* <Button><FaChevronLeft /> Back to main page</Button> */}
                <H1>About</H1>
                <Grid fluid>
                    <Row> 
                        <Column sm={12} md={6} lg={3} textalign="center">
                            <Img 
                                width={"300px"}
                                src={require("../../../assets/images/misc/ChristleReed.png")}
                            />
                            <H3>Christle Reed</H3>
                            <Body>Being Me KC Author</Body>
                        </Column>
                        <Column sm={12} md={6} lg={3} textalign="center">
                            <Img 
                                width={"380px"}
                                src={require("../../../assets/images/misc/LeviHoffmeier.png")}
                            />
                            <H3>Levi Hoffmeier</H3>
                            <Body>Being Me KC Illustrator</Body>
                        </Column>
                        <Column sm={12} md={6} lg={3} textalign="center">
                            <Img 
                                width={"225px"}
                                src={require("../../../assets/images/misc/AdriGuyer.png")}
                            />
                            <H3>Adri Guyer</H3>
                            <Body>Photography and Videography</Body>
                        </Column>
                        <Column sm={12} md={6} lg={3} textalign="center">
                            <Img 
                                width={"185px"}
                                src={require("../../../assets/images/misc/KristinDroege.png")}
                            />
                            <H3>Kristin Droege</H3>
                            <Body>Turn The Page KC Project Lead</Body>
                        </Column>
                    </Row>
                </Grid>
            </Wrapper>
                
            <FullWidthLine height="auto">
                <Centered style={{padding: "25px 0"}}>
                    <H2 color={"white"} margin="0">
                        Brought to you by:
                    </H2>
                    <ALink href="https://turnthepagekc.org/" target="_blank">
                        <Img 
                            width={"500px"}
                            src={require("../../../assets/images/logos/turn_the_page_logo.png")}
                        />
                    </ALink>
                </Centered>
            </FullWidthLine>
            <Wrapper>
                <ContactForm />
            </Wrapper>
        </>
    );
}

export default About;