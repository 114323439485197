import styled  from 'styled-components';

export const FooterContainer = styled.footer`
    padding: 20px 0;
    width: 100%;
    margin: auto;
    font-size: 14px;
    background-color: white;
    div {
        text-align: center;
    }
`;